import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, List, ListItemText, ListItem } from "@material-ui/core";
import { SpecFeature } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 2)
      },
      list: {
        display: 'flex',
        flexDirection: 'column',
      },
      item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }
    }
  ),
);

interface Props {
  data: SpecFeature
}

const SpecList: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h6">{data.category}</Typography>
      <List>
        {
          data.data.map((item, index) => {
            return (
              <ListItem key={'item-' + index}>
              <ListItemText primary={item.name} secondary={` ${item.value === 't' ? 'Included' : item.value} ${item.units ? item.units : ''}`}/>
            </ListItem>
            )
          })
        }
      </List>
    </div>
  );
};

export default SpecList;