import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  Paper,
  Tabs,
  Tab
} from "@material-ui/core";
import { CarMake, CarModel, ModelTrim } from "leasemojo-common";
import { graphql, Link } from "gatsby";
import orderBy from 'lodash/orderBy';

import Layout from '../components/Layout';
import SpecList from '../components/SpecList';
import TrimImage from "../components/CarImage/TrimImage";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),
      },
      select: {
        minWidth: 200,
        margin: theme.spacing(3, 0),
      },
      content: {
        overflow: 'hidden',
      }
    }
  ),
);


interface Props {
  data: {
    car: CarMake;
    model: CarModel;
    trim: ModelTrim;
    allTrim: {
      nodes: ModelTrim[]
    }
  }
}

const ModelSpecs: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { car, model, trim, allTrim } = data;

  const [ tab, setTab ] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  if (!trim.specs) {
    return null;
  }

  let specs = trim.specs.specs;
  if (tab === 1) {
    specs = trim.specs.features;
  }
  else if (tab === 2) {
    specs = trim.specs.safetyRatings;
  }

  return (
    <Layout contentClassName={classes.root}>
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          {data.model.modelYear} {data.car.name} {data.model.name}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="column" mt={2} mb={2}>
          <TrimImage trim={trim} size={300} color={trim.defaultColors.exteriorColorId} placeholderImage={data.model.placeholderImage} />

          <Select
            value={trim.id}
            className={classes.select}
          >
            {
              allTrim.nodes.map(item => (
                <MenuItem key={item.id} button component={Link} to={`/cars/${car.slug}/${model.slug}/${item.slug}/specs`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            }
          </Select>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            component={Link}
            to={`/build/${car.slug}/${model.slug}`}
          >
            Lease Similar Car
          </Button>
        </Box>
        <Paper className={classes.content}>
          <Tabs value={tab} variant="fullWidth" onChange={handleChange}>
            <Tab label="Specs" />
            <Tab label="Features" />
            <Tab label="Safty" />
          </Tabs>

          {
            orderBy(specs, 'order', 'asc').map((item, index) => <SpecList data={item} key={'spec-' + index} />)
          }
        </Paper>
      </Container>
    </Layout>
  );
};

export default ModelSpecs;

export const query = graphql`
  query($carId: String!, $modelId: String!, $trimId: String!) {
    car(id: {eq: $carId}) {
      id,
      name
      logoUrl
      slug
    }
    model(id: {eq: $modelId}) {
      id
      originalId,
      imageUrl
      placeholderImage
      name
      slug
      modelYear,
    }
    allTrim(
      filter: { modelId: { eq: $modelId } }
      sort: { fields: msrp, order: ASC }
    ) {
      nodes {
        id
        name
        slug
      }
    }
    trim(id: {eq: $trimId}) {
      id
      originalId
      name
      msrp
      defaultColors {
        exteriorColorId
        interiorColorId
      }
      exteriorColors {
        images {
          lg
          md
        }
        msrp
        name
        rgbHex
        id
      }
      interiorColors {
        id
        msrp
        name
      }
      slug
      engine
      driveTrain,
      specs {
        features {
          category
          data {
            name
            units
            value
          }
          order
        }
        safetyRatings {
          category
          data {
            name
            value
          }
          order
        }
        specs {
          category
          data {
            name
            units
            value
          }
          order
        }
      }
    }
  }
`
